import * as React from 'react'
import * as API from '../../Checkout/lib/api'
import { CheckCircleIcon } from '../../igniter/Icons'
import { parameterize } from '../../Utils/StringUtils'

interface IProps {
  header: string | React.ReactNode
  includedItems: API.IIncludedItem[]
  planName: string
}

export function IncludedItems({ header, includedItems, planName }: IProps) {
  const newEssentialsTag = 'NEW to Essentials!'
  const newStockTag = 'NEW to Igniter!'

  const includedItemListItems = includedItems.map(({ name, new: isNew }) => {
    const newTag = name == 'Countdowns' && planName == 'Essentials' ? newEssentialsTag
                         : name == 'Stock Photos' ? newStockTag
                         : name == 'Stock Videos' ? newStockTag
                         : ''
    return (
      <li key={parameterize(name)} className={parameterize(name)}>
        <CheckCircleIcon />
        {name}
        <span> {newTag}</span>
      </li>
    )
  })

  if (includedItems.length === 0) { return null }

  return (
    <div className="section middle">
      <h4>
        {header}
      </h4>
      <ul className="product-types">
        {includedItemListItems}
      </ul>
    </div>
  )
}

IncludedItems.defaultProps = {
  header: 'Unlimited Downloads of all:',
}
