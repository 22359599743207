import * as React from 'react'
import { useEffect } from 'react'
import { IAudioControls } from './hooks/use_audio_controls'

interface IProps {
  id: number
  src: string
  controls: IAudioControls
}

const AudioPlayer: React.FC<IProps> = ({ id, src, controls }) => {
  useEffect(() => {
    const audioElement = controls.ref.current
    const previousPlayedPercent = controls.getPreviouslyPlayedPosition({ id })

    if (audioElement) {
      audioElement.addEventListener('canplay', () => {
        if (previousPlayedPercent > 0.0 && previousPlayedPercent < 1.0) {
          let restartAtTime = audioElement.duration * previousPlayedPercent
          restartAtTime = isNaN(restartAtTime) ? 0.0 : restartAtTime
          audioElement.currentTime = restartAtTime
        }
      }, { once: true })
    }
  }, [])

  return (
    <audio ref={controls.ref} data-id={id}>
      <source src={src} />
    </audio>
  )
}

export { AudioPlayer }
