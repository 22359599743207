import { IProps } from './ProductProps'

export const extractTagSlug = ({ tag }: { tag?: Partial<IProductTag> }): string => {
  const tagName = tag && tag.name ? tag.name.toLowerCase() : ''

  if (tagName === 'stock music collections') {
    return 'collections'
  }
  return tagName ? tagName.replace(/\W+/g, '-') : ''
}

export const mergeProps = (...props: Array<Partial<IProps>>) => {
  return props.reduce((previous, next) => ({
    ...previous,
    ...Object.assign(
      {},
      ...Object.entries(next || {})
        .filter(([_, v]) => v !== undefined && v !== null)
        .map(([k, v]) => ({ [k]: v })),
    ),
  }))
}
