import React from 'react';
import Inflection from 'inflection';
import { parameterize } from 'Utils/StringUtils';

export function Filter(props) {
  const { groupName, id, name, selected } = props;
  const htmlID = `${parameterize(groupName)}-${id}`
  return(
    <li>
      <label htmlFor={htmlID} className="radio">
        <span className="radio__input">
          <input
            name={htmlID}
            id={htmlID}
            type="radio"
            value={name}
            checked={selected}
            onChange={props.onFilterChange}
          />
          <span className="radio__control"></span>
        </span>
        <span className="radio__label">{name}</span>
      </label>
    </li>
  );
}
