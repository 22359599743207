import * as React from 'react'
import { MembershipPlan } from '../../Checkout/lib/membership_plan'

interface IProps {
  membershipPlans: MembershipPlan[]
  selectedPlan?: string
  handlePlanChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

export function MembershipPlanSelectBox(props: IProps) {
  const { membershipPlans, selectedPlan, handlePlanChange } = props
  const planOptions = membershipPlans.map(({ name, slug }) => {
    return (
      <option key={slug} value={slug}>
        {name}
      </option>
    )
  })

  return (
    <select value={selectedPlan} onChange={handlePlanChange}>
      <option value="">
        Select any Media Type
      </option>
      {planOptions}
    </select>
  )
}
