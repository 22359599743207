import React from 'react';
import { GridContainer, Grid, Cell } from 'react-foundation';
import Inflection from 'inflection';
import Sticky from 'react-stickynode';
import { FilterButtonIcon } from 'igniter/Icons';
import { SubnavFilterLabel } from './SubnavFilterLabel';

export class Subnav extends React.Component {
  constructor(props) {
    super(props);
    this.state = { stickyStyles: {} };

    this.updateStickyStyles = this.updateStickyStyles.bind(this);
  }

  filterSidebarButtonText() {
    return(this.props.filterSidebarOpen ? 'Hide Filters' : 'Show Filters');
  }

  totalEntries() {
    const { paginationData } = this.props
    return paginationData.total_entries;
  }

  handleSearchChange(e) {
    const { onSearchChange } = this.props;
    onSearchChange(e.target.value);
  }

  handleSortChange(e) {
    const { onSortChange } = this.props;
    onSortChange(e.target.value);
  }

  componentDidMount() {
    this.promoBar = $('#promo-bar');
    this.mainNav = $('#main-nav');
    this.subNav = $('.subnav-bar');
    this.pageFooter = $('#page-footer');

    window.addEventListener('resize', this.updateStickyStyles);
    window.addEventListener('scroll', this.updateStickyStyles);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateStickyStyles);
    window.removeEventListener('scroll', this.updateStickyStyles);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.stickToTop !== this.props.stickToTop) {
      this.updateStickyStyles();
    }
  }

  updateStickyStyles() {
    const { stickToTop } = this.props;
    let stickyStyles;

    if (stickToTop) {
      let subnavTop;
      let promoBarHeight;
      const subNavHeight = this.subNav.outerHeight();
      const footerTop = this.pageFooter[0].getBoundingClientRect().top;
      const navHeight = this.mainNav.outerHeight();

      if (this.promoBar && this.promoBar.is(':visible')) {
        promoBarHeight = this.promoBar.outerHeight(true);
      } else {
        promoBarHeight = 0;
      }

      if ((footerTop - (promoBarHeight + navHeight + subNavHeight)) > 0) {
        subnavTop = promoBarHeight + navHeight;
      } else {
        subnavTop = footerTop - subNavHeight;
      }

      stickyStyles = { position: 'fixed', top: subnavTop };
    } else {
      stickyStyles = {};
    }

    this.setState({ stickyStyles: stickyStyles });
  }

  render() {
    const {
      showFilterSidebarToggle,
      filterSelections,
      onFilterSidebarToggleClick,
      onFilterDeselection,
      searchString,
      sortOptions,
      sortBy,
      pageTitle
    } = this.props;
    const { stickyStyles } = this.state;

    const filterLabels = filterSelections.map((selection) => {
      return(
        <SubnavFilterLabel
          key={selection.filterID}
          selection={selection}
          onFilterDeselection={onFilterDeselection}
        />
      );
    });

    const sortOptionTags = sortOptions.map((option) => {
      return(
        <option key={option.key} value={option.key}>
          {option.name}
        </option>
      );
    });

    const searchPlaceholder = `Search ${pageTitle}`;

    const formattedTotalEntries = this.totalEntries() ? this.totalEntries().toLocaleString() : ''

    return(
      <div className="subnav-bar" style={stickyStyles}>
        <GridContainer className="wide">
          <Grid className="grid-margin-x products-subnav">
            <Cell className="filter-toggle-cell" showFor="medium" isHidden={!showFilterSidebarToggle}>
              <button
                type="button"
                className="hollow button secondary expanded"
                onClick={() => onFilterSidebarToggleClick()}
              >
                <span>{this.filterSidebarButtonText()}</span>
                <span className="filter-button"><FilterButtonIcon /></span>
              </button>
            </Cell>

            <Cell auto="all" className="search-cell">
              <Grid className="grid-margin-x grid-margin-y align-justify">
                <Cell medium={24} small={40}>
                  <div className="search-wrapper">
                    <input
                      type="search"
                      value={searchString}
                      placeholder={searchPlaceholder}
                      onChange={(e) => this.handleSearchChange(e)}
                    />
                  </div>
                </Cell>
                <Cell auto="medium" showFor="medium">
                  <Grid className="grid-margin-x">
                    {filterLabels}
                  </Grid>
                </Cell>
                <Cell shrink="medium" small={20}>
                  <Grid className="products-sort align-center">
                    <Cell showFor="medium" shrink="all">
                      <label htmlFor="products-sort" className="middle text-right">
                        Sort {formattedTotalEntries} results by:&nbsp;
                      </label>
                    </Cell>
                    <Cell className="select-container" shrink="all">
                      <select
                        id="products-sort"
                        value={sortBy}
                        onChange={(e) => this.handleSortChange(e)}
                      >
                        {sortOptionTags}
                      </select>
                      <i className="fa fa-chevron-down"></i>
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </GridContainer>
      </div>
    );
  }
}
