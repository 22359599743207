import React from 'react';
import { Filter } from './Filter';
import { createClassName, generalClassNames } from 'react-foundation/lib/utils';

function FilterGroupContent(props) {
  const { groupName, filterSelection, numberOfFiltersToShow } = props;
  const className = createClassName(
    'filter-group-content',
    props.className,
    generalClassNames(props)
  );
  const emptySelection = !filterSelection.filterID;

  const filters = props
    .groupFilters
    .slice(0, numberOfFiltersToShow)
    .map((filter) => {
      const onFilterChange = (event) => {
        props.onFilterChange({
          groupName: groupName,
          filterID: filter.id,
          filterType: filter.type,
          filterName: filter.name,
        });
      }

    const selected = filterSelection &&
      filterSelection.filterID === filter.id

    return(
      <Filter
        key={filter.id}
        groupName={groupName}
        id={filter.id}
        name={filter.name}
        selected={selected}
        onFilterChange={onFilterChange}
      />
    );
  });

  return(
    <div className={className}>
      <ul>
        <Filter
          groupName={groupName}
          id={null}
          name="All"
          selected={emptySelection}
          onFilterChange={(e) => props.onFilterChange({ groupName: groupName })}
        />
        {filters}
      </ul>
    </div>
  );
}

export { FilterGroupContent };
