import * as React from 'react'

interface IProps {
  header?: string
  membershipPlanName: string
  benefits: string[]
}

export function MemberBenefits(props: IProps) {
  const { membershipPlanName, benefits } = props

  const memberBenefitListItems = benefits.map((benefit, index) => {
    return (
      <li key={index} dangerouslySetInnerHTML={{__html: benefit}}/>
    )
  })

  if (benefits.length === 0) { return null }

  return (
    <div className="section bottom">
      <ul className="benefits">
        {memberBenefitListItems}
      </ul>
    </div>
  )
}
