import React from 'react';
import { Grid, Cell } from 'react-foundation';
import { FilterGroup } from './FilterGroup';

export class FilterSidebar extends React.Component {
  _filterSelectionForGroup(groupName) {
    return this.props.filterSelections.find((selection) => {
      return selection.groupName === groupName;
    }) || { groupName: groupName };
  }

  render() {
    const { filterSidebarOpen, filterSelections, onFilterChange } = this.props;
    const filterGroups = this.props.filterGroups.map((filterGroup) => {
      const alwaysShowAll = filterGroup.name == 'Producer';
      return(
        <FilterGroup
          key={filterGroup.name}
          groupName={filterGroup.name}
          alwaysShowAll={alwaysShowAll}
          groupFilters={filterGroup.options}
          filterSelection={this._filterSelectionForGroup(filterGroup.name)}
          onFilterChange={onFilterChange}
        />
      );
    });


    return (
      filterGroups.length > 0
        ? (
          <Cell showFor="medium" isHidden={!filterSidebarOpen} id="filter-sidebar">
            <Grid>
              <ul className="filter-groups">
                {filterGroups}
              </ul>
            </Grid>
          </Cell>
        ) : null
    );
  }
}
