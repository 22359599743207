import React from 'react';
import { Cell, Label, Icon } from 'react-foundation';
import Inflection from 'inflection';

export function SubnavFilterLabel(props) {
  const { selection, onFilterDeselection } = props;
  const onClick = (_) => {
    onFilterDeselection(selection)
  }

  return(
    <Cell className="subnav-filter-label" shrink="all">
      <Label onClick={(e) => onClick(e)}>
        <Icon name="fa fa-times-circle"/> {selection.filterName}
      </Label>
    </Cell>
  );
}
