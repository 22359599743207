import * as moment from 'moment'
import * as API from './api'

export class Installment {
  public amount: number
  public dueOn: moment.Moment

  public constructor(api: API.IInstallment) {
    this.amount = api.amount
    this.dueOn = moment(api.due_on)
  }
}
