import * as React from 'react'
import videojs from 'video.js'
import 'videojs-flash'

const VideoPlayer: React.FunctionComponent<videojs.PlayerOptions> = (
  options,
) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null)
  const playerRef = React.useRef<videojs.Player | null>(null)

  React.useEffect(() => {
    if (videoRef.current && !playerRef.current) {
      playerRef.current = videojs(videoRef.current, options)
      playerRef.current.addClass('vjs-fill')
      playerRef.current.addClass('vjs-ig7')
    }
  }, [options, videoRef])

  React.useEffect(() => {
    const currentPlayer = playerRef.current

    return () => {
      if (currentPlayer) {
        currentPlayer.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return (
    <div data-vjs-player={true} style={{ height: '100%' }}>
      <video ref={videoRef} className="video-js vjs-big-play-centered" />
    </div>
  )
}

export { VideoPlayer }
