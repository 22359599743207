import * as API from './api'

export class PaymentOption {
  public fullPrice: number
  public price: number
  public priceBreakdown: API.IPriceBreakdown
  public monthly: boolean
  public numberOfPayments: number

  public constructor(api: API.IPaymentOption) {
    this.fullPrice = api.full_price
    this.price = api.price
    this.priceBreakdown = api.price_breakdown
    this.monthly = api.monthly
    this.numberOfPayments = api.number_of_payments
  }
}
