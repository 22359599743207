import * as React from 'react'

interface IProps {
  title?: string
  bucketContentsCount?: number
  link?: {
    text: string
    path: string
  }
  tagline?: string
}

const BucketHeader: React.FunctionComponent<IProps> = ({
  title = '',
  bucketContentsCount = 0,
  link,
  tagline = '',
}) => {
  let titleTag: React.ReactElement | string = title || ''
  let linkTag: React.ReactElement | string = ''
  let taglineTag: React.ReactElement | string = ''

  if (tagline) {
    taglineTag = <span className="tagline">{tagline}</span>
  } else if (bucketContentsCount > 0) {
    titleTag = `${titleTag} (${bucketContentsCount.toLocaleString()})`
  }

  if (link && link.path) {
    if (titleTag) {
      titleTag = <a href={link.path}>{titleTag}</a>
    }

    if (link.text) {
      linkTag = <a href={link.path}>{link.text}</a>
    }
  }

  return (
    <div className="grid-x grid-margin-x bucket-header">
      <div
        className="cell auto bucket-title"
        id={title.toLowerCase().replace(' ', '-')}
      >
        {titleTag}
        {taglineTag}
      </div>
      <div className="cell shrink text-right bucket-link caret">{linkTag}</div>
    </div>
  )
}

export { BucketHeader }
