import * as React from 'react'
import * as API from '../../Checkout/lib/api'

interface IProps {
  purchaseOptionName: API.PurchaseOptionNameType
  handleOptionChange: (newName: API.PurchaseOptionNameType) => void
}

export class MonthlyAnnualToggle extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props)
    this.state = {}
  }

  public render() {
    const { purchaseOptionName } = this.props

    return (
      <div className="grid-x align-center-middle toggle-wrapper">
        <div className={`cell text-label ${purchaseOptionName == 'monthly' ? 'active' : ''}`}>
          Pay Monthly
        </div>
        <div className="cell switch">
          <input
            className="switch-input"
            id="payment_type_monthly_annual"
            checked={purchaseOptionName === 'annual'}
            onChange={this.handleOptionChange}
            type="checkbox"
          />
          <label className="switch-paddle rounded" htmlFor="payment_type_monthly_annual" />
        </div>
        <div className={`cell text-label annual ${purchaseOptionName == 'annual' ? 'active' : ''} display-inline`}>
          Pay Yearly
          <span>Save More</span>
        </div>
      </div>
    )
  }

  private handleOptionChange = () => {
    const { purchaseOptionName, handleOptionChange } = this.props

    handleOptionChange(purchaseOptionName == 'annual' ? 'monthly' : 'annual')
  }
}
