import React from 'react';

export class SearchMetricsDebug extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  hoverOn() {
    this.setState({ hover: true });
  }

  hoverOff() {
    this.setState({ hover: false });
  }

  render() {
    if (this.props.product.solr == undefined)
      return null;

    const { debug, score } = this.props.product.solr;

    if (!debug)
      return null;

    const { hover } = this.state;
    const {
      components: formulaValues,
      computed_popularity: computedPopularity,
      formula_str: formulaString,
    } = debug;

    const style = {
      backgroundColor: 'rgba(255,255,255,0.85)',
      color: 'black',
      fontFamily: 'monospace',
      fontWeight: 'bold',
      left: '8px',
      padding: '2px 5px',
      position: 'absolute',
      top: '8px',
    };

    const popover = {
      display: 'inline-block',
      position: 'relative',
    };

    const popoverText = {
      backgroundColor: '#ffecc1',
      borderRadius: '2px',
      bottom: '30px',
      boxShadow: '0 0 4px #111',
      color: 'black',
      fontSize: '10px',
      padding: '5px 10px',
      position: 'absolute',
      textAlign: 'left',
      visibility: hover ? 'visible' : 'hidden',
      zIndex: 10000,
    };

    const popoverText2 = {
      ...popoverText,
      fontSize: '12px',
      height: '140px',
      top: '40px',
    };

    return (
      <div style={{position: 'absolute'}}>
        <div
          style={style}
          onMouseEnter={() => this.hoverOn()}
          onMouseLeave={() => this.hoverOff()}>P={computedPopularity}<br/>S={score}
        </div>
        <div style={popover}>
          <pre style={popoverText}>{JSON.stringify(formulaValues, null, 2)}</pre>
          <pre style={popoverText2}>{formulaString}</pre>
        </div>
      </div>
    );
  }
}
