import * as React from 'react'
import { MembershipPlan } from '../../Checkout/lib/membership_plan'
import { PurchaseOption } from '../../Checkout/lib/purchase_option'
import { CallToAction } from './CallToAction'
import { MembershipPlanPrice } from './MembershipPlanPrice'

declare var Routes: any

interface IProps {
  purchaseOption: PurchaseOption
  currentPlan?: MembershipPlan
  lastExpiringMembershipEndDate: string
  renewalDate: string
}

interface IState {}

export class LegacyCard extends React.Component<IProps, IState> {
  public whatsIncludedLink() {
    const { purchaseOption: { membershipPlan } } = this.props
    const { code, name } = membershipPlan
    let url

    if (code == 'BASIC') {
      url = 'https://ignitermedia.zendesk.com/hc/en-us/articles/360022599311'
    } else if (code == 'PLUS') {
      url = 'https://ignitermedia.zendesk.com/hc/en-us/articles/360025780592'
    } else if (code == 'YEAR-MM') {
      url = 'https://ignitermedia.zendesk.com/hc/en-us/articles/360025780492'
    } else if (code.startsWith('PICK1')) {
      url = 'https://ignitermedia.zendesk.com/hc/en-us/articles/360048439272-How-To-Videos-For-Your-Pick-One-Membership'
    }

    if (url !== undefined) {
      return (
        <a href={url} target="_blank">What's included in the {name} Membership?</a>
      )
    }
  }

  public membershipStatusText() {
    const { purchaseOption: { membershipPlan }, currentPlan, lastExpiringMembershipEndDate } = this.props
    const { name } = membershipPlan
    const endDateString = new Date(lastExpiringMembershipEndDate).toLocaleDateString()

    if (currentPlan && currentPlan.code === membershipPlan.code) {
      return (
        <p>
          You currently have a <strong>{name} Membership</strong> that expires on {endDateString}.
        </p>
      )
    } else {
      return (
        <p>
          Your <strong>{name} Membership</strong> has expired.
        </p>
      )
    }
  }

  public cardText() {
    const { purchaseOption: { membershipPlan, paymentOption }, renewalDate } = this.props
    const {
      name,
      code,
      transitionCTA,
    } = membershipPlan
    const {
      price,
      fullPrice,
    } = paymentOption
    const renewalDateString = new Date(renewalDate).toLocaleDateString()

    if (transitionCTA === 'not_eligible') {
      return (
        <p>
          Your membership will be eligible for renewal or downgrade on {renewalDateString}.{' '}
          Want to experience even more value in your membership?{' '}
          <a href={Routes.igniter_membership_checkout_path({plan_code: 'PREMIUM'})}>Upgrade today!</a>
        </p>
      )
    } else {
      if (price === fullPrice) {
        return (
          <p>
            You can <a href={Routes.igniter_membership_checkout_path({plan_code: code})}>renew today for ${price}</a>.
            Or, you can explore our new membership options below. {this.whatsIncludedLink()}
          </p>
        )
      } else {
        return (
          <p>
            A {name} Membership is ${fullPrice}, but because you are a member, you
            can <a href={Routes.igniter_membership_checkout_path({plan_code: code})}>renew today for ${price}</a>.
            Or, you can explore our new membership options below. {this.whatsIncludedLink()}
          </p>
        )
      }
    }
  }

  public render() {
    const { purchaseOption, renewalDate } = this.props
    const { membershipPlan } = purchaseOption
    const {
      code,
      transition,
      transitionCTA,
    } = membershipPlan

    return (
      <div className={`cell legacy-membership card ${code.toLowerCase()}`}>
        <div className="grid-x grid-padding-x">
          <div className="cell mlarge-auto left">
            {this.membershipStatusText()}
            {this.cardText()}
          </div>

          <div className="cell mlarge-shrink right">
            <MembershipPlanPrice
              purchaseOption={purchaseOption}
              ctaEnabled={transitionCTA !== 'not_eligible'}
            />
            <CallToAction
              purchaseOption={purchaseOption}
              transition={transition}
              transitionCTA={transitionCTA}
              renewalDate={renewalDate}
            />
          </div>
        </div>
      </div>
    )
  }
}
