import * as React from 'react'

import { IProps } from '../ProductProps'
import { AudioProduct } from './AudioProduct'
import { StillOrMotionProduct } from './StillOrMotionProduct'

export default (props: IProps): JSX.Element => {
  const isAudio = () => {
    const AUDIO_SITE_PRODUCT_TYPES = ['Stock Music', 'Sound Effects']
    const { product: { site_nav_parent_tag: siteNavTag } } = props

    return AUDIO_SITE_PRODUCT_TYPES.includes(siteNavTag?.name)
  }

  if (isAudio()) {
    return <AudioProduct {...props} />
  } else {
    return <StillOrMotionProduct {...props} />
  }
}
