import window from 'window';

function getCurrentLocation() {
  return window.location.pathname + window.location.search;
}

export default function ({ action, onChange }) {
  const beforeActionURI = getCurrentLocation();

  action();

  if (beforeActionURI !== getCurrentLocation()) {
    onChange();
  }
}
