import React from 'react';
import ReactPaginate from 'react-paginate';
import Analytics from '../../igniter/analytics';
import { Element, scroller } from 'react-scroll';
import { GridContainer, Grid, Cell } from 'react-foundation';
import { Subnav } from './Subnav';
import { FilterSidebar } from './FilterSidebar';
import { ProductGrid } from './ProductGrid';

export class ProductListInterface extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilterSidebarToggle: this.props.showFilterSidebarToggle,
      filterSidebarOpen: this.props.filterSidebarOpen,
      stickySubnav: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.promoBar = $('#promo-bar');
    this.mainNav = $('#main-nav');
    this.containerElement = $('#product-list-interface')[0]
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterSidebarOpen } = this.state;

    if (prevState.filterSidebarOpen !== filterSidebarOpen) {
      Analytics.track('crashdown-client.product-browse.toggle-filter-sidebar', {
        opened: filterSidebarOpen,
      });

      document.cookie = `show_filters=${filterSidebarOpen}; expires=Wed, 18 Dec 2025 12:00:00 UTC; path=/`
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    var viewportTop = this.containerElement.getBoundingClientRect().top;
    var navHeight = this.mainNav.outerHeight(true);
    var promoBarHeight;

    if (this.promoBar && this.promoBar.is(':visible')) {
      promoBarHeight = this.promoBar.outerHeight(true);
    } else {
      promoBarHeight = 0;
    }

    if(viewportTop < (navHeight + promoBarHeight)) {
      if(!this.state.stickySubnav)
        this.setState({ stickySubnav: true })
    }
    else {
      if(this.state.stickySubnav)
        this.setState({ stickySubnav: false })
    }
  }

  handleFilterSidebarToggleClick() {
    this.setState({
      filterSidebarOpen: !this.state.filterSidebarOpen,
    });
  }

  handleClearFilters() {
    const { handleQueryChanged } = this.props;

    handleQueryChanged({
      filterSelections: [],
      searchString: '',
      page: 1,
    });
  }

  handleFilterDeselection(selection) {
    const { groupName } = selection
    this.handleAddFilterSelection({ groupName: groupName });
  }

  handleAddFilterSelection(selection) {
    const { handleQueryChanged } = this.props;
    const { groupName, filterID } = selection;
    const selections = this._selectionsWithoutGroup(groupName)

    if (filterID) {
      selections.push(selection)
    }

    if(this.state.stickySubnav) {
      scroller.scrollTo('productGridContainer',{
        duration: 600,
        smooth: 'easeInOutQuad',
        offset: this.mainNav.outerHeight(true) * -1,
      })
    }

    handleQueryChanged({
      filterSelections: selections,
      page: 1,
    });
  }

  handlePageChange(page) {
    const { handleQueryChanged } = this.props;

    if(this.state.stickySubnav) {
      scroller.scrollTo('productGridContainer',{
        duration: 0,
        offset: this.mainNav.outerHeight(true) * -1,
      })
    }

    handleQueryChanged({
      page: page.selected + 1,
    });
  }

  handleSearchChange(searchString) {
    const { handleQueryChanged } = this.props;

    handleQueryChanged({
      searchString: searchString,
      page: 1
    });
  }

  handleSortChange(sortBy) {
    const { handleQueryChanged } = this.props;

    handleQueryChanged({
      sortBy: sortBy,
      page: 1
    });
  }

  _selectionsWithoutGroup(groupName) {
    return this.props.filterSelections.filter(function(currentSelection) {
      return currentSelection.groupName != groupName;
    });
  }

  render() {
    const {
      showFilterSidebarToggle,
      filterSidebarOpen,
      stickySubnav,
    } = this.state;
    const {
      filters,
      productGridProps,
      sortOptions,
      sortBy,
      filterSelections,
      searchString,
      products,
      productsMeta,
      productsLoading,
      pageTitle,
      page,
    } = this.props;
    const paginationData = productsMeta.pagination;

    const showNoProductsMessage = !productsLoading && paginationData.total_entries == 0

    const paginationClass = (showNoProductsMessage || productsLoading) ? 'hide' : 'show'

    var stickyContainer = { paddingTop: 0 }
    if(stickySubnav) {
      stickyContainer = { paddingTop: $('.subnav-bar').outerHeight() }
    }

    return(
      <Element id="product-list-interface" name='productGridContainer'>
        <Subnav
          showFilterSidebarToggle={showFilterSidebarToggle}
          filterSidebarOpen={filterSidebarOpen}
          onFilterSidebarToggleClick={() => this.handleFilterSidebarToggleClick()}
          sortOptions={sortOptions}
          sortBy={sortBy}
          stickToTop={stickySubnav}
          filterSelections={filterSelections}
          searchString={searchString}
          onSearchChange={(search) => this.handleSearchChange(search)}
          onSortChange={(sortBy) => this.handleSortChange(sortBy)}
          onFilterDeselection={(selection) => this.handleFilterDeselection(selection)}
          pageTitle={pageTitle}
          paginationData={paginationData}
        />
        <GridContainer
          style={stickyContainer}
          className={`wide products-list-container ${productsLoading ? 'loading' : ''}`}>
          <Grid className="grid-margin-x grid-margin-y">
            <FilterSidebar
              filterGroups={filters}
              showFilterSidebarToggle={showFilterSidebarToggle}
              filterSidebarOpen={filterSidebarOpen}
              filterSelections={filterSelections}
              onFilterChange={(selection) => this.handleAddFilterSelection(selection)}
            />

            <Cell auto="all" className="products">
              <ProductGrid
                products={products}
                loading={productsLoading}
                showEmptyMessage={showNoProductsMessage}
                emptyMessageText="Hmm, we don't have any products that match your filters or search terms."
                showLinkAction={true}
                linkActionText="Try clearing your filters"
                onLinkActionClick={(e) => { e.preventDefault(); this.handleClearFilters();}}
                {...productGridProps}
              />

              <ReactPaginate
                pageCount={paginationData.total_pages}
                pageRangeDisplayed={12}
                marginPagesDisplayed={2}
                forcePage={page - 1}
                onPageChange={(page) => this.handlePageChange(page)}
                containerClassName={`pagination text-center ${paginationClass}`}
                activeClassName="current"
                previousClassName="pagination-previous"
                nextClassName="pagination-next"
                breakLabel=" "
                breakClassName="ellipsis"
              />
            </Cell>
          </Grid>
        </GridContainer>
      </Element>
    );
  }
}
