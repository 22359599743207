import * as React from 'react'
import CountUp from 'react-countup'
import { PurchaseOption } from '../../Checkout/lib/purchase_option'

interface IProps {
  purchaseOption: PurchaseOption
  ctaEnabled: boolean
}

interface IState {
  previousPrice: number
  currentPrice: number
}

export class MembershipPlanPrice extends React.Component<IProps, IState> {
  public static getDerivedStateFromProps(props: IProps, state: IState): Partial<IState> | null {
    const { purchaseOption, ctaEnabled } = props
    const newPrice = MembershipPlanPrice.calculateYourPrice(purchaseOption, ctaEnabled)

    return {
      previousPrice: state.currentPrice,
      currentPrice: newPrice,
    }
  }

  public static calculateYourPrice(purchaseOption: PurchaseOption, ctaEnabled: boolean): number {
    return ctaEnabled ? purchaseOption.actualPrice() : purchaseOption.fullPrice()
  }

  public constructor(props: IProps) {
    super(props)

    this.state = {
      previousPrice: this.yourPrice(),
      currentPrice: this.yourPrice(),
    }
  }

  public render() {
    return (
      <div className="membership-pricing">
        {this.renderFullPriceBlock()}
        {this.renderYourPriceBlock()}
      </div>
    )
  }

  private renderFullPriceBlock() {
    const { purchaseOption, ctaEnabled } = this.props
    const { fullPrice, price } = purchaseOption.paymentOption

    if (price === fullPrice || !ctaEnabled) { return null }

    return (
      <span className="full-price">
        <sup className="currency">$</sup>
        <span className="amount">{purchaseOption.fullPrice()}</span>
        <sub className="term">&nbsp;/&nbsp;{purchaseOption.paymentFrequencyLabel()}</sub>
      </span>
    )
  }

  private renderYourPriceBlock() {
    const { previousPrice } = this.state
    const { purchaseOption } = this.props

    return (
      <span className="price">
        <span className="currency">$</span>
        <CountUp
          start={previousPrice}
          end={this.yourPrice()}
          duration={0.75}
        />
        <sub className="term">&nbsp;/&nbsp;{purchaseOption.paymentFrequencyLabel()}</sub>
      </span>
    )
  }

  private yourPrice(): number {
    const { purchaseOption, ctaEnabled } = this.props

    return MembershipPlanPrice.calculateYourPrice(purchaseOption, ctaEnabled)
  }
}
