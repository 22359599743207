import React from 'react';
import Analytics from '../../igniter/analytics';
import trackPageView from '../../igniter/analytics/factories/pageView';
import { createBrowserHistory as createHistory } from 'history';
import locationObserver from '../../Utils/locationObserver';
import { ProductListInterface } from './ProductListInterface';
import { queryStringParameter, encodeURIComponentWwwForm } from 'Utils';
import { DebugContext } from 'Utils/DebugContext';
import _ from 'lodash';

export class ProductListDataManagement extends React.Component {
  constructor(props) {
    super(props);
    const {
      page,
      pageTitle,
      products,
      productsMeta,
      filters,
      selectedFilters,
      searchString,
      sortBy,
    } = this.props;

    const {
      validSortOptions: sortOptions,
      includesRelevance,
    } = this.determineValidSortOptions(this.props);
    const searchSortBy = (sortBy && includesRelevance) ? sortBy : 'relevance';
    const browseSortBy = (sortBy && !includesRelevance) ? sortBy : sortOptions[0].key;
    const history = createHistory();

    this._searchInputTimer = null;

    // Avoid unsightly UI jumps on navigation if browser supports it.
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    const debugMode = queryStringParameter('debug') || false;

    this.state = {
      debugMode: debugMode,
      page: page || 1,
      products: products,
      productsMeta: productsMeta,
      filters: filters || [],
      productsLoading: products.length == 0,
      filterSelections: this._filterSelectionsFromProps(selectedFilters),
      searchString: searchString || "",
      sortOptions: sortOptions,
      searchSortBy: searchSortBy,
      browseSortBy: browseSortBy,
      history: history,
      unlistenHandler: history.listen((location, action) => {
        this._historyChanged(location, action)
      }),
    }
  }

  componentDidMount() {
    const { products } = this.state;
    const { passthroughParams } = this.props;

    if (products.length === 0) {
      this.handleQueryChanged({ passthroughParams: passthroughParams });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterSelections } = this.state;

    if (
      (prevState.filterSelections.length === 0 && filterSelections.length !== 0)
      || !prevState.filterSelections.every(el => filterSelections.includes(el))
    ) {
      Analytics.track('crashdown-client.product-browse.filter-interaction');
    }
  }

  componentWillUnmount() {
    const { unlistenHandler } = this.state;
    unlistenHandler();
  }

  determineValidSortOptions(query) {
    const { sortOptions, searchString } = query;

    const relevanceSort = sortOptions.find(el => el.key == 'relevance' );
    if (relevanceSort && (searchString === '' || searchString == null)) {
      return {
        validSortOptions: sortOptions.filter(el => el !== relevanceSort),
        includesRelevance: false,
      };
    } else {
      return {
        validSortOptions: sortOptions,
        includesRelevance: true,
      };
    }
  }

  handleQueryChanged(newQuery) {
    const newState = {};
    const { passthroughParams } = newQuery;
    const { sortOptions: allSortOptions } = this.props;
    const {
      debugMode,
      page,
      filterSelections,
      searchString,
      searchSortBy,
      browseSortBy,
    } = this.state;

    newState.debugMode = newQuery.debugMode || debugMode;
    newState.page = newQuery.page || page;
    newState.filterSelections = newQuery.filterSelections || filterSelections;

    if (newQuery.searchString !== undefined) {
      newState.searchString = newQuery.searchString;
    } else {
      newState.searchString = searchString;
    }

    const { validSortOptions, includesRelevance } = this.determineValidSortOptions({
      sortOptions: allSortOptions,
      searchString: newState.searchString,
    });

    newState.sortOptions = validSortOptions;
    newState.searchSortBy = searchSortBy;
    newState.browseSortBy = browseSortBy;

    if (includesRelevance) {
      newQuery.sortBy = newState.searchSortBy = newQuery.sortBy || searchSortBy || 'relevance';
    } else {
      newQuery.sortBy = newState.browseSortBy = newQuery.sortBy || browseSortBy;

      // As soon as we're browsing, reset the search state to
      // default to relevance for the next searchString
      newState.searchSortBy = 'relevance';
    }

    newState.productsLoading = true;

    if (!newQuery.skipHistorySave) {
      const newHistory = Object.assign(
        {},
        newState,
        {
          changedKeys: Object.keys(newQuery),
          passthroughParams,
        },
      );

      const saveMethod = this._calculateSaveHistoryMethod(newHistory.changedKeys);
      this._saveHistory(newHistory, saveMethod);
    }

    this._fetchProducts(newState);
    this.setState(newState);
  }

  _historyChanged(location, action) {
    if (action === 'POP' && location.state) {
      const {
        page,
        filterSelections,
        searchString,
        searchSortBy,
        browseSortBy,
      } = location.state;

      this.handleQueryChanged({
        page: page,
        filterSelections: filterSelections,
        searchString: searchString,
        sortBy: this.sortBy(location.state),
        skipHistorySave: true,
      });
    }
  }

  _filterSelectionsFromProps(selected) {
    const { filters } = this.props;

    return (selected || []).map(([type, id]) => {
      const filterGroup = filters.find((filter) => {
        return filter.options.find((option) => option.id === id && option.type === type)
      });

      if(!filterGroup) {
        return {
          groupName: 'Unknown',
          filterID: id,
          filterName: 'Unknown',
          filterType: type,
        };
      } else {
        return {
          groupName: filterGroup.name,
          filterID: id,
          filterName: filterGroup.options.find((tag) => tag.id === id).name,
          filterType: type
        }
      }

    });
  }

  _fetchProducts(newState) {
    if (this.state.fetchProductsRunning) {
      this.setState({ nextFetchProductsState: newState });
      return;
    }
    this.setState({ fetchProductsRunning: true })
    fetch(this._buildURL(newState, true), { credentials: 'include' })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          products: json.products,
          productsMeta: json.meta,
          page: json.meta.pagination.current_page,
          productsLoading: false,
          fetchProductsRunning: false,
        }, () => {
          if (this.state.nextFetchProductsState) {
            this._fetchProducts(this.state.nextFetchProductsState)
            this.setState({ nextFetchProductsState: null });
          }
        });
      });
  }

  _saveHistory(newHistory, saveMethod) {
    const url = this._buildURL(newHistory, false);
    const { history } = this.state;

    if (saveMethod === 'push') {
      locationObserver({
        action: () => history.push(url, newHistory),
        onChange: trackPageView,
      });
    } else if (saveMethod === 'replace') {
      history.replace(url, newHistory);

      // Debounce search input for pageview tracking
      clearTimeout(this._searchInputTimer);
      this._searchInputTimer = setTimeout(trackPageView, 1500);
    }
  }

  _calculateSaveHistoryMethod(changedKeys) {
    const { history } = this.state;
    const currentState = history.location.state || {};

    // If this is just a search string update after another search just replace
    // the previous history entry to avoid massive amounts of history being
    // added.
    if (this._onlyChangedSearchString(changedKeys)) {
      if (this._onlyChangedSearchString(currentState.changedKeys || {})) {
        return 'replace';
      }
    }

    // If nothing has changed just replace the current entry. This ensures we
    // get the history state set on initial page load
    if (changedKeys.length === 0) {
      return 'replace';
    }

    return 'push';
  }

  _onlyChangedSearchString(keys) {
    return keys.length === 2 && (
      keys.includes('searchString')
        && keys.includes('page') /* page is reset to 1 for each new search */
    );
  }

  _buildURL(newState, forAPIEndpoint) {
    const {
      filterSelections,
      page,
      searchString,
      sortOptions,
      searchSortBy,
      browseSortBy,
      passthroughParams,
    } = newState;
    const { productsEndpoint } = this.props;
    let queryParams = passthroughParams || {};
    const filterQueries = this.filterSelectionQueries(filterSelections);
    const debugMode = queryStringParameter('debug');

    let url;
    if (forAPIEndpoint) {
      url = productsEndpoint;
    } else {
      url = window.location.pathname;
    }

    const urlJoiner = url.includes('?') ? '&' : '?';

    if (page > 1) {
      queryParams.page = page;
    }

    if (searchString) {
      queryParams.q = encodeURIComponentWwwForm(searchString);
    }

    const sortBy = this.sortBy(newState);
    if (sortBy !== sortOptions[0].key) {
      queryParams.sort = sortBy;
    }

    if (!_.isEmpty(filterQueries)) {
      queryParams = { ...queryParams, ...filterQueries };
    }

    if (debugMode) {
      queryParams.debug = 1;
    }

    const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');

    return `${url}${urlJoiner}${queryString}`;
  }

  filterSelectionQueries(selections) {
    const grouped = selections
      .reduce((sum, selection) => {
        if (selection.filterType === 'tag') {
          sum.tag = sum.tag || [];
          sum.tag.push(selection.filterID);
        } else {
          sum[selection.filterType] = selection.filterID;
        }

        return sum;
      }, {});

    return grouped;
  }

  sortBy(state = this.state) {
    const { searchString, browseSortBy, searchSortBy } = state;
    return (searchString) ? searchSortBy : browseSortBy;
  }

  render() {
    const {
      debugMode,
      history,
      products,
      productsMeta,
      filters,
      productsLoading,
      page,
      filterSelections,
      searchString,
      sortOptions,
    } = this.state;

    const {
      pageTitle,
      productGridProps,
      showFilterSidebarToggle,
      filterSidebarOpen,
    } = this.props;

    return(
      <DebugContext.Provider value={debugMode}>
        <ProductListInterface
          handleQueryChanged={(query) => this.handleQueryChanged(query)}
          history={history}
          products={products}
          productsMeta={productsMeta}
          productsLoading={productsLoading}
          filters={filters}
          sortOptions={sortOptions}
          page={page}
          pageTitle={pageTitle}
          filterSelections={filterSelections}
          sortBy={this.sortBy()}
          searchString={searchString}
          productGridProps={productGridProps}
          showFilterSidebarToggle={filters.length > 0}
          filterSidebarOpen={filterSidebarOpen}
        />
      </DebugContext.Provider>
    );
  }
}

ProductListDataManagement.defaultProps = {
  products: [],
  page: 1,
  productsMeta: { pagination: {} },
  filterSidebarOpen: false,
}
