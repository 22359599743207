import * as React from 'react'
import { ProductThumbnail } from './ProductThumbnail'

interface IProps {
  urls: string[]
  altText: string
}

const CollectionCollage: React.FunctionComponent<IProps> = ({
  urls,
  altText,
}) => {
  const [primary, ...rest] = urls
  return (
    <div className="preview-collage">
      <div className="primary-image">
        <div className="aspect-container">
          <ProductThumbnail url={primary} altText={altText} />
        </div>
      </div>
      <div className="image-bar">
        {rest.map((url) => (
          <div key={url} className="secondary-image">
            <div className="aspect-container">
              <ProductThumbnail url={url} altText={altText} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export { CollectionCollage }
