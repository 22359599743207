import React from 'react';
import { BucketHeader } from '../BucketHeader';
import { ProductGrid } from '../ProductList/components/ProductGrid';

const DEFAULT_BUCKET_LIMIT = 4

export class ProductBucket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: props.products || [],
      totalCount: props.total_count || 0,
    }
  }

  componentDidMount() {
    const { products } = this.state;
    const { browse_bucket_path: browseBucketPath } = this.props;

    if (products.length == 0 && !!browseBucketPath) {
      this.fetchProducts();
    }
  }

  fetchProducts() {
    const { browse_bucket_path: browseBucketPath } = this.props;

    $.getJSON(browseBucketPath)
      .done((response) => {
        this.setState({
          products: response.products,
          totalCount: response.meta.pagination.total_entries,
        });
      })
  }

  className() {
    let className = "bucket";
    const { products } = this.state;

    if (products.length === 0) {
      className = `${className} hide`;
    }

    return className;
  }

  render() {
    const { title, tagline } = this.props;
    const { link, thumbnail_type: thumbnailType } = this.props;
    const limit = this.props.limit || DEFAULT_BUCKET_LIMIT;
    const { products, totalCount } = this.state;

    return (
      <div className="grid-container">
        <div className={this.className()}>
          <BucketHeader
            key="bucket-header"
            title={title}
            bucketContentsCount={totalCount}
            tagline={tagline}
            link={link}
          />
          <ProductGrid
            key="bucket-products"
            thumbnailType={thumbnailType}
            products={products.slice(0, limit)}
          />
        </div>
      </div>
    );
  }
}

export { BucketHeader };
