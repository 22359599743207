import * as React from 'react'
import * as API from '../../Checkout/lib/api'
import { PaymentDetails } from '../../Checkout/lib/payment_details'
import { InfoCircleIcon } from '../../igniter/Icons'

interface IProps {
  planName: string
  priceDetails: API.IPriceBreakdown
  paymentDetails: PaymentDetails
}

interface IState {
  tooltipShown: boolean
}

export class MembershipPlanDiscountBreakdown extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      tooltipShown: false,
    }
  }

  public showTooltip = () => { this.setState({ tooltipShown: true }) }
  public hideTooltip = () => { this.setState({ tooltipShown: false }) }

  public render() {
    const { tooltipShown } = this.state

    return (
      <span className="your-price" style={{ display: 'inline-block', position: 'relative' }}>
        <a
          className="membership-plans-tooltip-trigger"
          onMouseEnter={this.showTooltip}
          onMouseLeave={this.hideTooltip}
        >
          <InfoCircleIcon />
          <div
            className="membership-plans-tooltip"
            style={{ display: tooltipShown ? 'block' : 'none' }}
          >
            {this.renderPricingDetails()}
          </div>
        </a>
      </span>
    )
  }

  private renderPricingDetails() {
    const {
      priceDetails,
    } = this.props

    const {
      full_price: fullPrice,
      calculated_price: calculatedPrice,
    } = priceDetails

    return (
      <table>
        <thead>
          <tr>
            <th>
              {this.planNameTitle()}
            </th>
            <th>
              {this.renderPrice(fullPrice)}
            </th>
          </tr>
        </thead>
        <tbody>
          {this.renderAdjustmentRows()}
        </tbody>
        <tfoot>
          <tr>
            <td>
              Your Annual Price
            </td>
            <td>
              {this.renderPrice(calculatedPrice)}
            </td>
          </tr>
        </tfoot>
      </table>
    )
  }

  private renderAdjustmentRows() {
    const {
      priceDetails: { adjustments },
    } = this.props

    return (
      adjustments
        .map(({ label, value }) => {
          if (value === 0.0) { return null }

          return (
            <tr key={label}>
              <td>
                {label}
              </td>
              <td>
                {this.renderPrice(-value)}
              </td>
            </tr>
          )
        })
        .filter((a) => a)
    )
  }

  private renderPrice(price: number) {
    const prefix = price < 0 ? '-$' : '$'

    return (
      <span className="rendered-price">
        {prefix}{Math.abs(price).toFixed(2)}
      </span>
    )
  }

  private planNameTitle() {
    const {
      planName,
      paymentDetails,
    } = this.props
    const { numberOfPayments } = paymentDetails

    const title = `${planName} Annual Price`

    if (numberOfPayments == 1) {
      return title
    } else {
      return (
        <>
          {title}<br />
          (paid in {numberOfPayments} installments)
        </>
      )
    }
  }
}
