import * as React from 'react'
import { MembershipPlan } from '../../Checkout/lib/membership_plan'
import { PurchaseOption } from '../../Checkout/lib/purchase_option'
import { CallToAction } from './CallToAction'
import { IncludedItems } from './IncludedItems'
import { MemberBenefits } from './MemberBenefits'
import { MembershipPlanPrice } from './MembershipPlanPrice'
import { MembershipPlanSelectBox } from './MembershipPlanSelectBox'

interface IProps {
  key: string
  renewalDate: string
  currentPlan?: MembershipPlan
  currentMembershipIsLifetime: boolean
  purchaseOption: PurchaseOption
  trialEligible: boolean
  trialActivationPrice: number
}

interface IState {
  selectedPlanSlug?: string
}

export class Card extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    const selected = this.initialSelectedOption()

    this.state = {
      selectedPlanSlug: selected && selected.membershipPlan.slug,
    }
  }

  public initialSelectedOption(): PurchaseOption | undefined {
    const { purchaseOption, currentPlan } = this.props
    const { childPlanPurchaseOptions } = purchaseOption
    const urlParams = new URLSearchParams(window.location.search)
    const urlPickOne = urlParams.get('pick_one')

    if (!childPlanPurchaseOptions.length) { return }

    if (currentPlan) {
      const currentPlanOption = this.findOptionForPlanSlug(currentPlan.slug)

      if (currentPlanOption) { return currentPlanOption }
    }

    if (urlPickOne) {
      const urlOption = this.findOptionForPlanSlug(urlPickOne)

      if (urlOption) { return urlOption }
    }
  }

  public isCurrentMembership() {
    const { currentPlan } = this.props
    const purchaseOption = this.displayPurchaseOption()
    const { membershipPlan: { code } } = purchaseOption

    return (currentPlan && currentPlan.code) === code
  }

  public handleSelectedChildPlanChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = this.findOptionForPlanSlug(event.currentTarget.value)
    this.setState({
      selectedPlanSlug: selectedOption && selectedOption.membershipPlan.slug,
    })
  }

  public subheader() {
    const { purchaseOption } = this.props
    const { childPlanPurchaseOptions } = purchaseOption
    const { selectedPlanSlug } = this.state
    const { membershipPlan: { description } } = this.displayPurchaseOption()

    if (childPlanPurchaseOptions.length > 0) {
      return (
        <MembershipPlanSelectBox
          membershipPlans={childPlanPurchaseOptions.map((o) => o.membershipPlan)}
          selectedPlan={selectedPlanSlug}
          handlePlanChange={this.handleSelectedChildPlanChange}
        />
      )
    } else {
      return <h5>{description}</h5>
    }
  }

  public bestValueBadge() {
    const { purchaseOption } = this.props
    const { membershipPlan: plan } = purchaseOption

    if (plan.name == 'Pro') {
      return (
        <div className="best-value">
          <p>Best Value</p>
        </div>
      )
    }
  }

  public render() {
    const purchaseOption = this.displayPurchaseOption()
    const { membershipPlan: plan } = purchaseOption

    return (
      <div
        className={`cell card ${plan.code.toLowerCase()} ${this.isCurrentMembership() ? 'current-membership' : ''}`}
      >
        {this.bestValueBadge()}
        {this.isCurrentMembership() ? this.renderCurrentPlanBug() : null}
        <div key="top" className="section top">
          <h4>
            {plan.name}
          </h4>
          {this.subheader()}

          {this.renderPlanPrice()}
          {this.renderCTA()}
        </div>
        <IncludedItems
          key="included-items"
          includedItems={plan.includedItems}
          header={plan.includedItemsHeader}
          planName={plan.name}
        />
        <MemberBenefits
          key="member-benefits"
          membershipPlanName={plan.name}
          benefits={plan.benefits}
        />
      </div>
    )
  }

  private renderCurrentPlanBug() {
    return (
      <div key="current-plan" className="section" id="current-plan">Your Current Plan</div>
    )
  }

  private renderPlanPrice() {
    const { currentMembershipIsLifetime } = this.props
    const purchaseOption = this.displayPurchaseOption()
    const { membershipPlan: plan } = purchaseOption

    if (currentMembershipIsLifetime) { return }

    return (
      <MembershipPlanPrice
        purchaseOption={purchaseOption}
        ctaEnabled={plan.transitionCTA !== 'not_eligible'}
      />
      )
  }

  private renderCTA() {
    const { renewalDate, trialEligible, trialActivationPrice, currentMembershipIsLifetime } = this.props
    const purchaseOption = this.displayPurchaseOption()
    const { membershipPlan: plan } = purchaseOption

    if (currentMembershipIsLifetime) { return }

    return (
      <CallToAction
        purchaseOption={purchaseOption}
        transition={plan.transition}
        transitionCTA={plan.transitionCTA}
        renewalDate={renewalDate}
        trialEligible={trialEligible}
        trialActivationPrice={trialActivationPrice}
      />
    )
  }

  private displayPurchaseOption(): PurchaseOption {
    const { purchaseOption } = this.props
    const selectedOption = this.selectedOption()

    return selectedOption ? selectedOption : purchaseOption
  }

  private findOptionForPlanSlug(slug: string): PurchaseOption | undefined {
    const { purchaseOption } = this.props
    const { childPlanPurchaseOptions } = purchaseOption

    return childPlanPurchaseOptions.find((o) => o.membershipPlan.slug === slug)
  }

  private selectedOption(): PurchaseOption | undefined {
    const { selectedPlanSlug } = this.state
    const { purchaseOption: parentPurchaseOption } = this.props
    const { childPlanPurchaseOptions } = parentPurchaseOption

    return childPlanPurchaseOptions.find((o) => o.membershipPlan.slug === selectedPlanSlug)
  }
}
