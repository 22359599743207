import * as API from './api'
import { Installment } from './installment'
import { TaxInformation } from './tax_information'

export class PaymentDetails {
  public paymentTerm: string
  public numberOfPayments: number
  public basePrice: number
  public priceBeforeTax: number
  public adjustments: API.IAdjustment[]
  public installments: Installment[]

  public constructor(api: API.IPaymentDetails) {
    this.paymentTerm = api.payment_term
    this.numberOfPayments = api.number_of_payments
    this.basePrice = api.base_price
    this.priceBeforeTax = api.price_before_tax
    this.adjustments = api.adjustments
    this.installments = api.installments.map((i) => new Installment(i))
  }

  public total({ taxInformation }: { taxInformation: TaxInformation }): number {
    const { priceBeforeTax } = this

    return priceBeforeTax + (taxInformation.salesTaxAmount || 0)
  }
}
