import React from 'react';
import { FilterGroupContent } from './FilterGroupContent';

const INITIAL_SHOWN_FILTER_COUNT = 6;

export class FilterGroup extends React.Component {
  constructor() {
    super();
    this.state = {
      showAllFilters: false,
    };
  }

  onShowMoreToggle() {
    this.setState({
      showAllFilters: !this.state.showAllFilters,
    });
  }

  _numberOfFiltersToShow() {
    const { showAllFilters } = this.state;
    const { groupFilters, alwaysShowAll } = this.props;

    if (showAllFilters || alwaysShowAll) {
      return groupFilters.length;
    } else {
      return INITIAL_SHOWN_FILTER_COUNT;
    }
  }

  _showHideLinkVisible() {
    const { groupFilters, alwaysShowAll } = this.props;

    return !alwaysShowAll && groupFilters.length > INITIAL_SHOWN_FILTER_COUNT;
  }

  render() {
    const { groupName, groupFilters, filterSelection, onFilterChange } = this.props;
    const { showAllFilters } = this.state;

    return(
      <li className="filter-group">
        <div className="filter-group-title">
          {groupName}
        </div>
        <FilterGroupContent
          numberOfFiltersToShow={this._numberOfFiltersToShow()}
          groupName={groupName}
          groupFilters={groupFilters}
          filterSelection={filterSelection}
          onFilterChange={onFilterChange}
        />
        <a
          className={`filter-group-show-more ${this._showHideLinkVisible() ? '' : 'hidden'}`}
          onClick={() => this.onShowMoreToggle()}
        >
          {showAllFilters ? '- less' : '+ more'}
        </a>
      </li>
    );
  }
}
