import CrashdownData from 'CrashdownData';
import Analytics from '..';

export default function () {
  Analytics.track('crashdown-client.pages.view', {
    plan_name: CrashdownData.userData.membershipPlanName,
    email: CrashdownData.userData.email,
    activeCampaignSiteTrackingAccountID: CrashdownData.activeCampaign.siteTrackingAccountID,
  });
}
