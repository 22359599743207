import * as React from 'react'
import * as API from '../Checkout/lib/api'
import { MembershipPlan } from '../Checkout/lib/membership_plan'
import { PurchaseOption } from '../Checkout/lib/purchase_option'
import { Card } from './components/Card'
import { LegacyCard } from './components/LegacyCard'
import { MonthlyAnnualToggle } from './components/MonthlyAnnualToggle'

export interface IProps {
  current_membership_plan?: API.IMembershipPlan
  current_membership_is_lifetime: boolean
  last_expiring_membership_end_date: string
  renewal_date: string
  legacy_purchase_options: API.IPurchaseOption[]
  purchase_options: API.IPurchaseOption[]
  default_purchase_option_name: API.PurchaseOptionNameType
  trial_eligible: boolean
  trial_activation_price: number
}

interface IState {
  purchaseOptionName: API.PurchaseOptionNameType
}

export class MembershipPlanCards extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      purchaseOptionName: props.default_purchase_option_name,
    }
  }

  public render() {
    const {
      current_membership_plan: rawCurrentPlan,
      current_membership_is_lifetime: currentMembershipIsLifetime,
      last_expiring_membership_end_date: lastExpiringMembershipEndDate,
      renewal_date: renewalDate,
      legacy_purchase_options: rawLegacyPurchaseOptions,
      purchase_options: rawPurchaseOptions,
      trial_eligible: trialEligible,
      trial_activation_price: trialActivationPrice,
    } = this.props
    const { purchaseOptionName } = this.state

    const legacyPurchaseOption = rawLegacyPurchaseOptions.map((o) => new PurchaseOption(o))
    const currentPlan = rawCurrentPlan ? new MembershipPlan(rawCurrentPlan) : undefined
    const purchaseOptions = rawPurchaseOptions.map((raw) => new PurchaseOption(raw))

    const displayLegacyPurchaseOption = legacyPurchaseOption.filter((o) => o.name == purchaseOptionName)[0]
    const displayPurchaseOptions = purchaseOptions.filter((o) => o.name == purchaseOptionName)

    let legacyPlanCard: React.ReactNode
    if (displayLegacyPurchaseOption) {
      legacyPlanCard = (
        <LegacyCard
          key="legacy-plan"
          purchaseOption={displayLegacyPurchaseOption}
          lastExpiringMembershipEndDate={lastExpiringMembershipEndDate}
          currentPlan={currentPlan}
          renewalDate={renewalDate}
        />
      )
    }

    const membershipPlanCards = displayPurchaseOptions.map((option) => {
      return(
        <Card
          key={option.membershipPlan.code}
          renewalDate={renewalDate}
          currentPlan={currentPlan}
          currentMembershipIsLifetime={currentMembershipIsLifetime}
          purchaseOption={option}
          trialEligible={trialEligible}
          trialActivationPrice={trialActivationPrice}
        />
      )
    })

    const withCurrent = !!currentPlan

    return (
      <>
        {this.renderPaymentOptionToggle()}
        <div className={`grid-x align-center membership-plan-cards ${withCurrent ? 'current-plan-shown' : ''}`}>
          {legacyPlanCard}
          {membershipPlanCards}
        </div>
      </>
    )
  }

  private handlePurchaseOptionNameChange = (newName: API.PurchaseOptionNameType) => {
    this.setState({ purchaseOptionName: newName })
  }

  private renderPaymentOptionToggle() {
    const { purchaseOptionName } = this.state

    return (
      <div className="membership-plan-cards-payment-toggle">
        <MonthlyAnnualToggle
          purchaseOptionName={purchaseOptionName}
          handleOptionChange={this.handlePurchaseOptionNameChange}
        />
      </div>
    )
  }
}
