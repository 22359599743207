import * as API from './api'
import { MembershipPlan } from './membership_plan'
import { PaymentDetails } from './payment_details'
import { PaymentOption } from './payment_option'

export class PurchaseOption {
  public name: API.PurchaseOptionNameType
  public label: string
  public membershipPlan: MembershipPlan
  public paymentOption: PaymentOption
  public baseTrialActivationPrice: number
  public trialActivationPrice: number
  public paymentDetails: PaymentDetails
  public childPlanPurchaseOptions: PurchaseOption[]
  public sameAsCurrentBilling: boolean

  public constructor(api: API.IPurchaseOption) {
    this.name = api.name
    this.label = api.label
    this.membershipPlan = new MembershipPlan(api.membership_plan)
    this.paymentOption = new PaymentOption(api.payment_option)
    this.baseTrialActivationPrice = api.base_trial_activation_price
    this.trialActivationPrice = this.baseTrialActivationPrice
    this.paymentDetails = new PaymentDetails(api.payment_details)
    this.childPlanPurchaseOptions = api.child_plan_purchase_options.map((o) => new PurchaseOption(o))
    this.sameAsCurrentBilling = api.same_as_current_billing
  }

  public fullPrice(): number {
    const {
      name,
      paymentOption: { fullPrice },
    } = this

    if (name === 'annual') {
      return fullPrice
    } else {
      return fullPrice / 12
    }
  }

  public actualPrice(): number {
    const { paymentDetails } = this

    return paymentDetails.priceBeforeTax
  }

  public paymentFrequencyLabel(): string {
    if (this.name === 'annual') {
      return 'year'
    } else {
      return 'month for 1 year'
    }
  }
}
