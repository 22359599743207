import * as React from 'react'
import * as API from './api'

export class MembershipPlan {
  public benefits: string[]
  public checkoutBenefits: string[]
  public code: string
  public description: string
  public includedItemsHeader: string | React.ReactNode | undefined
  public includedItems: API.IIncludedItem[]
  public includedItemsInTrial: string[]
  public totalIncludedItemsInTrial: number
  public name: string
  public slug: string
  public transition: string
  public transitionCTA: string

  public constructor(api: API.IMembershipPlan) {
    this.benefits = api.benefits
    this.checkoutBenefits = api.checkout_benefits
    this.code = api.code
    this.description = api.description
    this.includedItemsHeader = api.included_items_header
    this.includedItems = api.included_items
    this.includedItemsInTrial = api.included_items_in_trial
    this.totalIncludedItemsInTrial = api.total_included_items_in_trial
    this.name = api.name
    this.slug = api.slug
    this.transition = api.transition
    this.transitionCTA = api.transition_cta
  }
}
