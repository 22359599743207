import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

interface IAudioControls {
  ref: RefObject<HTMLAudioElement>
  pos: number
  setPos: (percent: number) => void
  getPreviouslyPlayedPosition: (object: { id: string | number }) => number
  playing: boolean
  toggle: () => void
  play: () => void
  pause: () => void
}

const playPercentKey: (id: string | number) => string = (id) => {
  return `play-percent-${id}`
}

const updatePlayTracking: (object: { id: string | number, percent: number }) => void = ({ id, percent }) => {
  const key = playPercentKey(id)
  const currentValue = parseFloat(localStorage.getItem(key) || '0.0')

  if (percent > currentValue) {
    localStorage.setItem(key, percent.toString())
  }
}

const getPreviouslyPlayedPosition: (object: { id: string | number }) => number = ({ id }) => {
  const key = playPercentKey(id)
  return parseFloat(localStorage.getItem(key) || '0.0')
}

const useAudioControls: () => IAudioControls = () => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [pos, setPos] = useState<number>(0)
  const [playing, setPlaying] = useState<boolean>(false)

  const handlePlayingChange = useCallback((newPlaying) => {
    const el = audioRef.current

    if (!el) { return }

    if (newPlaying) {
      el.play()
    } else {
      el.pause()
    }
  }, [audioRef, setPlaying])

  const handlePosChange = useCallback((percent) => {
    const el = audioRef.current

    if (el && el.duration) {
      el.currentTime = Math.round(percent * el.duration)
    }

    handlePlayingChange(true)
  }, [audioRef, handlePlayingChange, setPos])

  // Respond to events directly applied to <audio> element
  useEffect(() => {
    const el = audioRef.current

    if (!el) { return }

    const timeHandler = () => {
      const percent = el.currentTime / el.duration
      setPos(percent)
      if (el.dataset.id) {
        updatePlayTracking({ id: el.dataset.id, percent })
      }
    }

    const pauseHandler = () => {
      setPlaying(!el.paused)

      if (!el.paused) {
        document
          .querySelectorAll<HTMLAudioElement>(`audio[data-id]:not([data-id="${el.dataset.id}"])`)
          .forEach((otherPlayer) => otherPlayer.pause())
      }
    }

    el.addEventListener('timeupdate', timeHandler)
    el.addEventListener('pause', pauseHandler)
    el.addEventListener('play', pauseHandler)

    return () => {
      el.removeEventListener('timeupdate', timeHandler)
      el.removeEventListener('pause', pauseHandler)
      el.removeEventListener('play', pauseHandler)
    }
  }, [audioRef])

  return {
    ref: audioRef,
    pos,
    setPos: handlePosChange,
    getPreviouslyPlayedPosition,
    playing,
    toggle: () => handlePlayingChange(!playing),
    play: () => handlePlayingChange(true),
    pause: () => handlePlayingChange(false),
  }
}

export { useAudioControls, IAudioControls }
