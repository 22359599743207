import { useCallback, useMemo, useRef, useState } from 'react'
import * as React from 'react'

import { IAudioControls } from './hooks/use_audio_controls'

interface IProps {
  productId: number
  controls: IAudioControls
}

const DEFAULT_PLAY_FILL_POSITION = 300

const Waveform: React.FC<IProps> = ({ productId, controls }) => {
  const element = useRef<HTMLDivElement>(null)
  const [scrubPosition, setScrubPosition] = useState<number | null>(null)
  const playPosition = controls.pos

  const handleMouseMove = useCallback((event) => {
    if (element.current) {
      const rect = element.current.getBoundingClientRect()
      const position = (event.pageX - rect.left) / rect.width
      if (position < 0) {
        setScrubPosition(0)
      } else if (position > 1) {
        setScrubPosition(1)
      } else {
        setScrubPosition(position)
      }
    }
  }, [setScrubPosition])

  const handleMouseLeave = useCallback(() => {
    setScrubPosition(null)
  }, [setScrubPosition])

  const handleMouseClick = useCallback(() => {
    if (scrubPosition) {
      controls.setPos(scrubPosition)
    }
  }, [scrubPosition, controls.setPos])

  const playFillPosition = useMemo(() => {
    const [playedPosition, scaleFactor] = (element.current) ?
      [playPosition, element.current.getBoundingClientRect().width] :
      [controls.getPreviouslyPlayedPosition({ id: productId }), DEFAULT_PLAY_FILL_POSITION]

    const posRight = 1 - playedPosition
    const pixRight = Math.round(scaleFactor * posRight)

    return pixRight
  }, [playPosition])

  return (
    <div className="waveform">
      <div
        ref={element}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onClick={handleMouseClick}
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          height: '100%',
        }}
      >
        { scrubPosition !== null && (
          <div
            className="playhead"
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: `${scrubPosition * 100}%`,
              width: 2,
            }}
          >
            &nbsp;
          </div>
        )}
        { playPosition !== null && (
          <div
            className="played-fill"
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: `${playFillPosition}px`,
              mask: `url(/products/${productId}/waveforms.svg) no-repeat 0 center`,
            }}
          >
            &nbsp;
          </div>
        )}
      </div>
      <img src={`/products/${productId}/waveforms.svg`} />
    </div>
  )
}

export { Waveform }
