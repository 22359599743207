import { Duration } from 'luxon'
import * as React from 'react'

interface IProps {
  url: string
  altText: string
  duration?: number
  visible?: boolean
}

const ProductThumbnail: React.FunctionComponent<IProps> = ({
  url,
  altText,
  duration,
  visible = true,
}) => {
  const durationString = Duration.fromMillis((duration || 0) * 1000).toFormat(
    'm:ss',
  )

  return (
    <div className="image-container">
      <div
        className="image"
        data-duration={durationString}
        style={{ backgroundImage: `url(${url})`, visibility: visible ? 'visible' : 'hidden' }}
      >
        <span className="product-badge" />
        <img src={url} alt={altText} />
      </div>
    </div>
  )
}

export { ProductThumbnail }
